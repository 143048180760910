.button {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    height: 2.7rem;
    border-radius: 6px;
    padding: 0 1rem 0 1rem;
    cursor: pointer;
    outline: none;
    font-size: 0.85rem;
    font-weight: 800;
    font-family: var(--font-bold);
    border: none;
    background-color: var(--quinary);
    color: var(--primary);
}

.button:hover {
    background-color: #42ac9f;
}