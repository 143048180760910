.banner {
    user-select: none;
    position: fixed;
    bottom: 0;
    left: 1rem;
    right: 1rem;
    z-index: 3;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
}

.banner__container {
    display: flex;
    background-color: white;
    border-radius: 6px;
    min-height: 3.4rem;
    box-shadow: 0 2px 8px 1px rgba(0,0,0,0.16);
    height: min-content;
    width: 100%;
    margin: 0 0 1rem 0;
}

.banner__icon {
    display: flex;
    width: 5rem;
    min-width: 4rem;
    justify-content: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.banner__icon svg {
    width: 2.5rem;
}

.banner__icon--alert {
    color: white;
    background-color: #F6AE2D;
}

.banner__icon--alert {
    color: #ffffff;
}

.banner__close {
    display: flex;
    align-items: flex-start;
    justify-content: right;
    padding: 1rem 1rem 1rem 0.5rem;
    color: var(--senary);
    cursor: pointer;
}

.banner__close svg {
    width: 1rem;
}

.banner__message-container {
    padding: 1.25rem 0 1.25rem 1.5rem;
    font-size: 0.8rem;
    color: var(--quarternary);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    word-break: break-word;
    min-width: 12rem;
}

.banner__message-header {
    color: var(--secondary);
    font-weight: 600;
    font-size: 1.1rem;
    font-family: var(--font-bold);
    margin: 0 0 0.25rem 0;
}

.banner__message-body {
    color: var(--quarternary);
    font-size: 0.9rem;
}

@media only screen and (min-width: 768px) { 
    .banner {
        bottom: 2rem;
        left: 3rem;
        right: inherit;
    }

    .banner__container {
        width: none;
    }

    .banner__message-container {
        max-width: 23rem;
    }

    .banner__icon {
        min-width: 3rem;
    }
}
