.profile {
    display: flex;
    min-height: 100vh;
}

.profile__left-container {
    display: flex;
    background-image: url('../../images/background.png');
}

.profile__right-container {
    display: flex;
    flex-basis: 100%;
}

.profile__iframe {
    flex-grow: 1;
}

.profile__loading {
    background-color: white;
    display: flex;
    flex-grow: 1;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 60rem) {
    .profile__left-container {
        display: flex;
        flex-grow: 1;
    }
    
    .profile__right-container {
        max-width: 30rem;
        width: 30rem;
    }
}