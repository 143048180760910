.logout {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    min-height: 100vh;
}

.logout__container {
    color: var(--primary);
    background-color: var(--primary);
    border-radius: 15px;
    width: 20rem;
    height: 30rem;
    margin: 3rem;
}

.logout__title {
    color: var(--secondary);
    font-weight: 800;
    font-size: 1.75rem;
    font-family: var(--font-bold);
    text-align: center;
    margin: 5rem 3rem 0 3rem;
}

.logout__description {
    color: var(--quarternary);
    font-size: 0.9rem;
    font-family: var(--font);
    text-align: center;
    margin: 1rem 3rem 0 3rem;
}

.logout__image {
    display: flex;
    margin: 2rem 0 0 0;
}

.logout__image img {
    max-width: 100%;
    max-height: 100%;
}

