html, body {
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: 'Gotham Book', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-scroll {
    margin: 0; 
    height: 100%; 
    overflow: hidden;
}  

:root {
  --primary: white;
  --secondary: #031E45;
  --tertiary: #ebf2f7;
  --quarternary: #6b7280;
  --quinary: #47baad;
  --senary: #e6e6e6;
  --godzilla: #8c99aa;

  --success: #00bda4;
  --warning: #FFA552;
  --error: #cf2b31;

  --font: 'Gotham Book', sans-serif;
  --font-medium: 'Gotham Medium', sans-serif;
  --font-bold: 'Gotham Bold', sans-serif;

  --header-size: 1.6rem;
  --subheader-size: 1.4rem;
  --body-size: 0.9rem;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
 
::-webkit-scrollbar-thumb {
  background: var(--quarternary);
}

@font-face {
  font-family: 'Gotham Book';
  font-weight: 400;
  src: url('./fonts/GothamSSm-Book_Web.woff2') format('woff2');
}

@font-face {
  font-family: 'Gotham Medium';
  font-weight: 500;
  src: url('./fonts/GothamSSm-Medium_Web.woff2') format('woff2');
}

@font-face {
  font-family: 'Gotham Bold';
  font-weight: 700;
  src: url('./fonts/GothamSSm-Bold_Web.woff2') format('woff2');
}
