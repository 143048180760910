.confirmation {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 5;
}

.confirmation__container {
    display: flex;
    flex-direction: column;
    flex-grow: 23;
    user-select: none;
    background-color: white;
    align-items: center;
}

.confirmation__icon {
    display: flex;
    margin: auto 0 0 0;
}

.confirmation__icon svg {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    display: block;
    stroke-width: 4;
    stroke: white;
    stroke-miterlimit: 10;
    margin: 7.5rem auto 1rem auto;
    box-shadow: inset 0px 0px 0px white;
    animation: fill .4s ease-in-out .4s forwards;
    display: flex;
    justify-content: center;
}

.confirmation__icon circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 4;
    stroke-miterlimit: 10;
    stroke: white;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.confirmation__icon path {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px white;
  }
}

.confirmation__button--error .button {
    background-color: var(--error);
}

.confirmation__button--success circle {
    background-color: var(--quarternary);
}

.confirmation__icon--error circle {
    fill: var(--error);
}

.confirmation__icon--success circle {
    fill: var(--success);
}

.confirmation__title {
    margin: 1rem 3rem 0.5rem 3rem;
    color: var(--secondary);
    font-weight: 800;
    font-size: 1.75rem;
    font-family: var(--font-bold);
    text-align: center;
}

.confirmation__message {
    color: var(--quarternary);
    margin: 0rem 3rem 2rem 3rem;
    font-size: 0.9rem;
    text-align: center;
    max-width: 20rem;
}

.confirmation__button {
    display: flex;
    align-content: center;   
    padding: 0 2rem 2rem 2rem;
    margin: auto 0 0 0;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
}

.confirmation__button button {
    max-width: 25rem;
}

@media only screen and (min-width: 768px) {
    .confirmation {
        display: flex;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(5px)
    }

    .confirmation__container {
        display: flex;
        flex-grow: 0;
        border-radius: 10px;
        z-index: 3;
        
    }

    .confirmation__blur {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 2;
        background-color: black;
        opacity: 0.8;
    }

    .confirmation__icon {
        margin: 0 0 0 0;
    }
}
