.authenticate {
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem 2rem 2rem;
    background: white;
    width: 100%;
    gap: 1.5rem;
}

.authenticate a {
    text-decoration: none;
}

.authenticate__image-container {
    display: flex;
    justify-content: center;
    margin: 0 auto 0 auto;
    display: flex;
    position: relative;
    width: min-content;
}

.authenticate__image-container--profile {
    margin: 0 auto 0 0;
}

.authenticate__image {
    width: 20rem;
}

.authenticate__text-container {
    display: grid;
    gap: 1rem;
}

.authenticate__title {
    color: var(--secondary);
    font-weight: 800;
    font-size: 1.6rem;
    font-family: var(--font-bold);
}

.authenticate__description {
    color: var(--quarternary);
    font-size: 0.9rem;
}

.authenticate__error {
    font-size: 0.85rem;
    color: var(--warning);
    word-break: break-word;
}

.authenticate__input-container {
    display: grid;
    gap: 1.5rem;
}

.authenticate__new {
    color: var(--quinary);
    font-size: 0.85rem;
    font-family: var(--font-bold);
    text-align: center;
    cursor: pointer;
    user-select: none;
}

.authenticate__forgot-password {
    cursor: pointer;
    color: var(--quinary);
    font-size: 0.85rem;
    font-family: var(--font-medium);
    text-align: center;
    user-select: none;
}

.authenticate__qr {
    display: flex;
    justify-content: center;
    padding: 1.5rem 0 0 0;
}

.authenticate__copy-qr {
    text-align: center;
    color: var(--quinary);
    font-size: 0.9rem;
    font-family: var(--font-bold);
    font-weight: 700;
    margin: 0 2rem 2rem 2rem;
    cursor: pointer;
}

.authenticate__profile-image {
    width: 7rem;
    height: 7rem;
    min-height: 7rem;
    min-width: 7rem;
    border-radius: 50%;
    border: 4px solid var(--quinary);
}

.authenticate__image-edit {
    position: absolute;
    bottom: -0.5rem;
    right: -0.5rem;
    background-color: var(--quinary);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.authenticate__image-edit svg {
    color: white;
    width: 1.75rem;
    height: 1.75rem;
    min-width: 1.75rem;
    min-height: 1.75rem;
}

.authenticate__loading {
    background-color: white;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 60rem) {
    .authenticate {
        padding: 6rem 6rem 6rem 6rem;
        box-sizing: border-box;
        gap: 2.5rem;
    }
}