.checkbox {
    display: flex;
    align-items: center;
  }
  
  .checkbox__label {
    user-select: none;
    color: var(--quarternary);
    font-size: 0.85rem;
    font-family: var(--font-medium);
  }
  
  input[type=checkbox] {
    display: none;
  }
  
  input[type=checkbox] + label {
    position: relative;
    height: 1.5rem;
    width: 1.5rem;
    display: block;
    transition: box-shadow 0.4s, border 0.4s;
    border: solid 2px var(--senary);
    box-shadow: 0 0 1px var(--senary);
    cursor: pointer;
    border-radius: 4px;
    margin: 0.75rem 0.75rem 0.75rem 0;
  }
  
  input[type=checkbox] + label:hover,
  input[type=checkbox]:checked + label {
    border: solid 2px var(--quinary);
  }
  
  input[type=checkbox]:checked + label:after {
    content: '\2714';
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    color: var(--primary);
    text-align: center;
    background-color: var(--quinary);
  }
  