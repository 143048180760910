.input {
    position: relative;
    display: flex;
}

.input__field {
    border: 2px solid #f4f6fa !important;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 0.9rem;
    height: 2.7rem;
    outline: none;
    padding: 0 0.6rem;
    position: relative;
    background-color: var(--tertiary);
    color: var(--quarternary);
    flex-grow: 1;
}

.input__field:focus {
    border: 2px solid var(--quinary) !important;
}

.input__field:invalid {
    border: 2px solid var(--warning) !important;
}

.input__locked {
    position: absolute;
    right: 1rem;
    top: 0.75rem;
    width: 1rem;
    height: 1rem;
    min-width: 1rem;
    min-height: 1rem;
}

.input__locked svg {
    color: var(--godzilla);
}